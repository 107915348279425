html {
  box-sizing: border-box;
}
*, *::before, *::after {
  box-sizing: inherit;
}

/*69696969696969696969696969696969696969696969696969696969*/

body {
  overflow: hidden;
  background-color: #008080;
  padding: 10px;
}

.desktop {
  display: flex;
  align-self: start;
}

.text-not-selected {
  padding-top: 3px;
  font-size: 12px;
  color: aliceblue;
}

.text-selected {
  background-color: #000080;
}

.desktop-icon {
  width: 60px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desk-icon {
  height: 50px;
  width: 50px;
}

.explorer {
  display: flex;
  direction: column;
  flex-flow: row wrap;
}

.explorer > * {
  margin: 10px;
}

.explorer > * > p {
  color: black;
}


/************************  Window  ************************/

.window-css {
  display: flex;
  flex-direction: column;
  height: 70vh;
  width: 60vw;
  margin-right: -55vw;
}

.window-body {
  height: 100%;
}


.window-inner {
  height: 100%;
  max-height: 100%;
  padding: 1vw;
  border: 2px inset ;
  background-color: white;
}

.bar-p ::first-letter {
  text-decoration:underline;
}

.navBar {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/************************ notepad ************************/

.notepad-bar {
  margin: 5px;
  display: flex;
  align-items: center;
}

.notepad-bar > p {
  margin-left: 5px;
  color: black;
}

.notepad-scroll {
  overflow: scroll;
}


/********************** Aboutme.md **********************/

.normal {
  font-family: "Lucida Console", "Courier New", monospace;
}

#wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#first {
  flex: 3;
}

#second {
  flex: 1;
}

/********************** AccessDen  **********************/

.access-denied {
  position: absolute;
  height: 100px;
  width: 200;
  top: 30%;
  left: 30%; 
  z-index: 999999;
}

.access-denied > .window-body {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.text-icon {
  display: flex;
  justify-content: space-around;
}

.text-icon > p {
  font-weight: bold;
}

.access-denied > .window-body > button {
  width: 1vw;
}

/**********************  taskBar   **********************/

.footer {
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4vh;
  color: white;
  text-align: center;
}

.footer > button {
  padding: 3px;
  margin-right: 10px;
}

.footer > button > img {
  width: 80%;
}

.taskElem {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 120px;
}

.taskElem > img {
  height:100%;
  width: auto;
  flex: 1;
}

.taskElem > p {
  flex: 9;
}

.button-active {
  box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px #808080;
}